import {
  addToHomeData,
  getProductsMyUsualsV2,
  useEvents,
  useLocalAddress,
  useQuery,
  useSession,
  useUpdateFromShelf,
  visiblePromos
} from '@smu-chile/pkg-unimarc-hooks'
import React, { useEffect, useRef, useState } from 'react'
import {
  useProdutsCarousel,
  useShelvesData
} from '../ProductsCarousel/helpers/useProdutsCarousel'
import { mapProductsToOldSchema } from '../../shared/helpers/mapProducts/mapProductsToOldSchema'
import {
  BackboneShelves,
  Column,
  Divider,
  Row,
  ShelfCarousel,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import Link from 'next/link'
import Image from 'next/future/image'
import { useFavoriteProductsManager } from 'shared/hooks/useFavoriteProductsManager'

interface CarouselMyUsualProps {
  ean: string
  isMobile?: boolean
  customItemToSlide?: number
  customItemToShow?: number
  handleOpenMyListsModal?: (item: IProductInList) => void
  onClickProduct?: () => void
}
export const CarouselMyUsualComponent = ({
  ean,
  isMobile,
  customItemToSlide,
  customItemToShow,
  handleOpenMyListsModal,
  onClickProduct
}: CarouselMyUsualProps) => {
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const { isLoggedIn, isLoading: sessionLoading } = useSession()
  const [isIntersecting, setIsIntersecting] = useState(false)
  const carouselRef = useRef(null)
  const carouselView = useRef(0)
  const listId = 'myusuals_pdp'
  const listName = 'My usuals - PDP'
  const site = 'Unimarc'
  const { hasLocalAddress } = useLocalAddress()
  const { handleToggleFavorite, isProductFavorite, ToastFavoriteUI } =
    useFavoriteProductsManager()

  const { isLoading, data: productMyUsual } = useQuery(
    ['searches-my-usuals-v2', ean],
    () => {
      return getProductsMyUsualsV2() // productsMyUsualsV2() = retorna data mockeada ,  getMyUsualsV2() retorna la respuesta del servicio
    },
    { enabled: isLoggedIn }
  )
  const products = mapProductsToOldSchema(productMyUsual?.products || [])

  const handlePDPShelf = ({ ref, shelf }) => {
    addToHomeData({
      ref,
      promoData: { ...shelf, promoTitle: '' },
      isShelf: true
    })
  }

  const handleIntersect = (entries) => {
    const [entry] = entries
    setIsIntersecting(entry.isIntersecting)
  }

  const { positionContainer, itemsToShow, itemsToSlide, spacerBelow } =
    useProdutsCarousel({ isMobile })

  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading,
    products,
    isMyUsuals: true,
    hasLocalAddress
  })

  const productsMemo = useShelvesData(shelvesData, isMobile) || []
  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, options)
    if (carouselRef.current) observer.observe(carouselRef.current)
    carouselView.current += 1
    return () => {
      if (carouselRef.current && carouselView.current === 1)
        observer.unobserve(carouselRef.current)
    }
  }, [carouselRef, options])

  if (!sessionLoading && !isLoggedIn) {
    return null
  }

  if (!Array.isArray(productsMemo) || productsMemo?.length === 0) {
    return null
  }

  const isLoadingMyUsual: boolean = isLoading

  const carouselMyUsualShelfData = {
    positionContainer,
    productsMemo,
    itemsToShow: customItemToShow || itemsToShow,
    itemsToSlide: customItemToSlide || itemsToSlide,
    spacerBelow,
    handleOnClick,
    handleAdd,
    handleRemove,
    handleChange
  }

  if (isLoadingMyUsual) {
    return (
      <BackboneShelves
        shelvesQuantityDesktop={5}
        shelvesQuantityMobile={2}
      />
    )
  }

  return (
    <>
      {Array.isArray(products) && products?.length > 0 && (
        <>
          <ToastFavoriteUI isMobile={isMobile} />
          <Row isWrap>
            {isMobile ? (
              <Column padding='0 20px 0 10px'>
                <Divider
                  borderHeight='3xs'
                  color={getGlobalStyle('--color-neutral-gray-divider')}
                  verticalMargin={26}
                />
                <Title
                  color={getGlobalStyle('--color-text-black')}
                  customFontSize='16px'
                  fontWeight='medium'
                  headingLevel='h4'
                  text='Te podría interesar'
                />
              </Column>
            ) : (
              <>
                <Divider
                  borderHeight='3xs'
                  color={getGlobalStyle('--color-neutral-gray-divider')}
                  verticalMargin={26}
                />
                <Title
                  color={getGlobalStyle('--color-text-black')}
                  customFontSize='16px'
                  fontWeight='medium'
                  headingLevel='h4'
                  text='Te podría interesar'
                />
                <Divider
                  borderHeight='3xs'
                  color={getGlobalStyle('--color-neutral-gray-divider')}
                  verticalMargin={24}
                />
              </>
            )}
            <Row
              justifyContent='center'
              margin='0 0 12px'
              maxWidth='1120px'
            >
              <Column>
                <Row
                  padding='10px'
                  ref={carouselRef}
                >
                  {isIntersecting &&
                    visiblePromos({
                      site,
                      listIdVal: listId,
                      listNameVal: listName
                    })}
                  <ShelfCarousel
                    carouselProps={{
                      infinite: true,
                      autoPlay: false,
                      shouldResetAutoplay: false,
                      afterChange: () => {
                        return visiblePromos({
                          site,
                          listIdVal: listId,
                          listNameVal: listName
                        })
                      },
                      partialVisible: true
                    }}
                    catchShelfRef={handlePDPShelf}
                    containerProps={carouselMyUsualShelfData.positionContainer}
                    handleOnClick={carouselMyUsualShelfData.handleOnClick}
                    handleOpenMyListsModal={handleOpenMyListsModal}
                    isMobile={isMobile}
                    isProductFavorite={isProductFavorite}
                    items={carouselMyUsualShelfData.productsMemo}
                    itemsToShow={carouselMyUsualShelfData.itemsToShow}
                    linkWrapper={Link}
                    nextImage={Image}
                    onClickProduct={onClickProduct}
                    onToggleFavorite={handleToggleFavorite}
                    quantityButtonProps={{
                      handleAdd: carouselMyUsualShelfData.handleAdd,
                      handleRemove: carouselMyUsualShelfData.handleRemove,
                      handleChange: carouselMyUsualShelfData.handleChange
                    }}
                    quantityButtonStyles={{
                      fontSize: 'md',
                      margin: '0px',
                      maxHeight: '30px',
                      maxWidth: '163px',
                      sizeCircle: 22
                    }}
                    showAddToFavoriteButton={true}
                    showAddToListButton={true}
                    showArrows={!isMobile}
                    showCouponButton={false}
                    slidesToSlide={carouselMyUsualShelfData.itemsToSlide}
                  />
                </Row>
              </Column>
            </Row>
          </Row>
        </>
      )}
    </>
  )
}
function handleCarousel(prevState, nextState) {
  return prevState.ean === nextState.ean
}
export const CarouselMyUsual = React.memo(
  CarouselMyUsualComponent,
  handleCarousel
)
