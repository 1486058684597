export const cleanPageNumber = (url: string) => {
  return url?.replace?.(/\/page-\d*/g, '')
}

export const cleanPageNumberParam = (params: Array<string>) => {
  return params?.filter?.((item) => {
    return !item.includes('page')
  })
}

export const checkNonRoutes = (pathName: string) => {
  const routeList = [
    'brand',
    'category',
    'coupon',
    'live-shopping-unimarc',
    '/p',
    'brand',
    'product',
    'robots.txt',
    'sitemap',
    'sitemap.xml',
    'ofertas',
    'favicon.ico',
    'version.json',
    'page',
    'search',
    'static',
    '404',
    '500',
    'acerca-nosotros',
    'bases-condiciones',
    'cambios-y-devoluciones',
    'fundacion-descubre',
    'locales',
    'medidas-covid',
    'politica-cookies',
    'preguntas-frecuentes',
    'proveedores',
    'terminos-condiciones'
  ]

  return routeList.some((route) => {
    return pathName.includes(route)
  })
}

export const pathStaticList = [
  {
    name: 'bases-condiciones',
    alias: 'Bases y condiciones'
  },
  {
    name: 'terminos-condiciones',
    alias: 'Términos y condiciones'
  },
  {
    name: 'acerca-nosotros',
    alias: 'Acerca de nosotros'
  },
  {
    name: 'cambios-y-devoluciones',
    alias: 'Cambios y devoluciones'
  },
  {
    name: 'fundacion-descubreme',
    alias: 'Fundación descúbreme'
  },
  {
    name: 'medidas-covid',
    alias: 'Medidas covid'
  },
  {
    name: 'politica-cookies',
    alias: 'Política de cookies'
  },
  {
    name: 'preguntas-frecuentes',
    alias: 'Preguntas frecuentes'
  },
  {
    name: 'locales',
    alias: 'Locales'
  },
  {
    name: 'proveedores',
    alias: 'Proveedores'
  },
  {
    name: 'como-comprar',
    alias: 'Como comprar'
  }
]

export const setPageLocation = () => {
  const section = window.location.pathname.toString().split('/')[1]

  const pageValidate = {
    category: 'PLP',
    search: 'SRP',
    product: 'PDP',
    ofertas: 'ofertas',
    coupon: 'cupones',
    brand: 'marca'
  }

  return pageValidate[section] || 'Home'
}

export const checkStaticRedirect = (pathName: string) => {
  return pathStaticList.some((path: object) => {
    return path?.['name'].includes(pathName.replace('/', ''))
  })
}

export const checkWrongPaginationUrl = (url: string[]) => {
  return url.filter((cat) => {
    return cat?.match(/page-\d*/g)?.[0]
  })
}

export const isURL = ({ url }: { url: string }) => {
  try {
    return Boolean(new URL(url))
  } catch (e) {
    return false
  }
}

export const setBannerLocation = (locationId: string) => {
  const section = window.location.pathname.toString().split('/')[1]
  const search = Object.fromEntries(new URLSearchParams(window.location.search))
  const pageValidate = {
    category: 'PLP',
    search: `SRP ${search.q}`,
    ofertas: 'ofertas'
  }
  return pageValidate[section] || locationId
}
