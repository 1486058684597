import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import {
  Column,
  Container,
  MobileModalWrapper,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  isValidArrayWithData,
  useGetByPromotion,
  useMobile,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { ShelfPromoTooltip } from '@smu-chile/pkg-unimarc-components/stories/organisms/Shelf/v2/ShelfPromoTooltip'
import { getTooltipPromoData } from '@smu-chile/pkg-unimarc-components/stories/organisms/Shelf/utils'
import { PromoCombineModalBackbone } from './Backbones'
import { useFavoriteProductsManager } from 'shared/hooks/useFavoriteProductsManager'
import { transformPromotionItems } from 'shared/helpers'
import {
  BigScreen,
  getBemId,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { SideModal } from './SideModal'
import { PromoCombineShelf } from './PromoCombineShelf'
import { usePromoCombine } from 'shared/utils/hooks'
import styles from './PromoCombineModal.module.css'

interface PromoCombineModalProps {
  clusterName?: string
  hasLocalAddress?: boolean
  isMobile?: boolean
  itemRequired?: number
  isOrderFormLoading?: boolean
  isOpen?: boolean
  promotionId?: string
  promotionMesage?: string
  typePromotion?: string
  onClickOpenModal?: () => void
}

let debounce

export const PromoCombineModal = ({
  clusterName,
  hasLocalAddress,
  itemRequired,
  isOpen,
  isOrderFormLoading,
  promotionId,
  promotionMesage,
  typePromotion,
  onClickOpenModal
}: PromoCombineModalProps) => {
  const [products, setProducts] = useState([])
  const [openTooltip, setOpenTooltip] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const shelvesInfoRef = useRef([])
  const { data, isLoading: isLoadingProducts } = useGetByPromotion(promotionId)
  const { isProductFavorite, handleToggleFavorite } =
    useFavoriteProductsManager()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const handleShowModal = async () => {
    onClickOpenModal()
  }

  const conditionTooltip = (isAdded?: boolean) => {
    if (isAdded === true) {
      clearTimeout(debounce)
      setOpenTooltip(true)
      debounce = setTimeout(() => {
        setOpenTooltip(false)
      }, 3000)
    } else {
      setOpenTooltip(false)
    }
  }

  const {
    shelvesData,
    handleAdd: handleAddFromShelf,
    handleChange,
    handleOnClick: handleOnClickFromShelf,
    handleRemove: handleRemoveFromShelf
  } = useUpdateFromShelf({
    clusterName,
    isOrderFormLoading,
    products,
    hasLocalAddress
  })

  const { addToPromotion, getPromotionQuantity } = usePromoCombine()

  const handleAdd = (props) => {
    handleAddFromShelf(props)
    addToPromotion(promotionId, 1)
  }

  const handleRemove = (props) => {
    handleRemoveFromShelf(props)
    addToPromotion(promotionId, -1)
  }

  const handleOnClick = (props) => {
    handleOnClickFromShelf(props)
    addToPromotion(promotionId, 1)
  }

  const { titleMessage, subtitle, colorBackground, isComplete } =
    getTooltipPromoData(itemRequired, quantity)

  useEffect(() => {
    if (promotionId && data?.availableProducts && data?.notAvailableProducts) {
      setProducts(
        transformPromotionItems([
          ...data.availableProducts,
          ...data.notAvailableProducts
        ])
      )
    }
    if (isOpen) {
      const q = getPromotionQuantity(promotionId)
      setQuantity(q)
    }
  }, [isOpen, isLoadingProducts])

  return (
    <>
      <BigScreen>
        <SideModal
          handleShowModal={handleShowModal}
          isOpen={isOpen}
          width='554px'
        >
          {isLoadingProducts ? (
            <PromoCombineModalBackbone />
          ) : (
            <Column
              overflow='scroll'
              padding='20px 0 0 15px'
            >
              {openTooltip && (
                <Container
                  absoluteDefault='bottomRight'
                  customClassName={classNames(styles['shelf__tooltipPosition'])}
                  maxHeight='48px'
                  position='absolute'
                  width='fit-content'
                  zIndex='3'
                >
                  <ShelfPromoTooltip
                    backgroundColor={colorBackground}
                    borderRadius='12px'
                    fontSize='md'
                    heigth='48px'
                    isMobile={isMobile}
                    maxWidth='173px'
                    padding='6px 0'
                    subTitle={subtitle}
                    textColor={isComplete ? 'black' : 'white'}
                    textLineHeight='17.64px'
                    title={titleMessage}
                  />
                </Container>
              )}
              {typePromotion === 'mxn' ? (
                <>
                  <Text
                    fontSize='md'
                    fontWeight='bold'
                  >
                    {promotionMesage}
                  </Text>
                  <Text fontSize='md'>
                    Elige tus productos y lleva la promo.
                  </Text>
                </>
              ) : (
                <Text fontSize='md'>Elige tus productos y lleva la promo.</Text>
              )}
              <Row
                gap='9px'
                isWrap={true}
              >
                {isValidArrayWithData(shelvesData) &&
                  shelvesData.map((product, index) => {
                    return (
                      <PromoCombineShelf
                        conditionTooltip={conditionTooltip}
                        handleAdd={handleAdd}
                        handleChange={handleChange}
                        handleOnClick={handleOnClick}
                        handleRemove={handleRemove}
                        handleToggleFavorite={handleToggleFavorite}
                        index={index}
                        isMobile={isMobile}
                        isProductFavorite={isProductFavorite}
                        key={product?.itemId}
                        product={product}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        shelvesInfoRef={shelvesInfoRef}
                      />
                    )
                  })}
              </Row>
            </Column>
          )}
        </SideModal>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId={getBemId('combinePromo', 'modal')}
          body={
            isLoadingProducts ? (
              <PromoCombineModalBackbone />
            ) : (
              <Column
                customHeight='100%'
                padding='20px 0 0'
              >
                {openTooltip && (
                  <Container
                    absoluteDefault='bottomRight'
                    customClassName={classNames(
                      styles['shelf__tooltipPosition--mobile']
                    )}
                    position='absolute'
                    width='fit-content'
                    zIndex='3'
                  >
                    <ShelfPromoTooltip
                      backgroundColor={colorBackground}
                      borderRadius='12px'
                      fontSize='md'
                      isMobile={isMobile}
                      maxWidth='140px'
                      subTitle={subtitle}
                      textColor={isComplete ? 'black' : 'white'}
                      textLineHeight='17.64px'
                      title={titleMessage}
                    />
                  </Container>
                )}
                {typePromotion === 'mxn' ? (
                  <>
                    <Text
                      fontSize='md'
                      fontWeight='semibold'
                    >
                      {promotionMesage}
                    </Text>
                    <Text fontSize='md'>
                      Elige tus productos y lleva la promo.
                    </Text>
                  </>
                ) : (
                  <Text fontSize='md'>
                    Elige tus productos y lleva la promo.
                  </Text>
                )}
                <Row
                  gap='9px'
                  isWrap={true}
                >
                  {isValidArrayWithData(shelvesData) &&
                    shelvesData.map((product, index) => {
                      return (
                        <PromoCombineShelf
                          conditionTooltip={conditionTooltip}
                          handleAdd={handleAdd}
                          handleChange={handleChange}
                          handleOnClick={handleOnClick}
                          handleRemove={handleRemove}
                          handleToggleFavorite={handleToggleFavorite}
                          index={index}
                          isMobile={isMobile}
                          isProductFavorite={isProductFavorite}
                          key={product?.itemId}
                          product={product}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          shelvesInfoRef={shelvesInfoRef}
                        />
                      )
                    })}
                </Row>
              </Column>
            )
          }
          bodyContainerProps={{
            customClassName: styles['modal__bodyContainer']
          }}
          dragProps={{
            isDraggable: false
          }}
          draggButton={false}
          hasFooter={false}
          hasShadow={false}
          headerCloseIconProps={{
            name: 'CloseThin',
            sizes: '2xs'
          }}
          headerProps={{
            customClassName: styles['modal__header--mobile']
          }}
          headerTitle='Combina la promo'
          hideHeaderDivider={false}
          iconSize={0}
          isEnableButton={false}
          modalConfigsProps={{
            isAutoHeight: false,
            isOpen,
            toggle: handleShowModal,
            toggleOutside: handleShowModal,
            minHeightFullScreen: 'calc(100vh - 60px)',
            maxHeightFullScreen: '590px'
          }}
          onClose={handleShowModal}
        />
      </SmallScreen>
    </>
  )
}
