import { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  addToHomeData,
  clickedPromos,
  ContentfulResponse,
  generateBreadcrumbJoin,
  IContentfulResponse,
  isValidArrayWithData,
  useContentful,
  useMobile,
  useOnScreen,
  useSession,
  useSponsoredBannersHome,
  useUserWithMembership,
  visiblePromosScroll
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Container,
  Column,
  PromotionalModalProps,
  TimeOfferBannerProps,
  HtmlRenderProps,
  DownloadableTermsAndConditions
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IInAppModal } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IInAppModal'
import {
  PropsHome,
  SendPromoDataImageBanner,
  SendPromoDataProps
} from 'shared/interfaces/Home'
import { RenderProductsCarouselProps } from 'components/ProductsCarousel/RenderProductsCarousel'
import {
  getPromotionalModalSeenCookie,
  setPromotionalModalSeenCookie
} from './PromotionalModal/helpers'
import { BackboneShowCaseWeb } from './Backbone/BackboneShowCaseWeb'
import { BackboneHome } from './Backbone'
import { PropsBannersWeb } from './BannersWeb'
import { IShowCaseWebProps } from './ShowCaseWeb'
import { offerBannerValidator } from '../../shared/helpers/offerBannerValidator'
import { checkDevice } from '../../shared/utils/isMobile'
import { PagesContainerDescription } from 'components/PagesContainerDescription/PagesContainerDescription'
import { useFullHiderFooter } from 'shared/utils/hooks'
import {
  FeatureContentItem,
  FeatureContentItemFormat
} from 'shared/interfaces/ClubUnimarc'
import { handleSponsoredBannersHome } from 'shared/helpers'
import { SponsoredBanner } from './SponsoredBanner'
import { Form } from 'components/Form'
import { BaseContainer } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'

const ShowCaseWeb = dynamic<IShowCaseWebProps>(
  () => {
    return import('./ShowCaseWeb').then((mod) => {
      return mod.ShowCaseWeb
    })
  },
  {
    loading: () => {
      return <BackboneShowCaseWeb />
    }
  }
)

const PromotionalModal = dynamic<PromotionalModalProps>(() => {
  return import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.PromotionalModal
  })
})

const TimeOfferBanner = dynamic<TimeOfferBannerProps>(() => {
  return import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.TimeOfferBanner
  })
})

const RenderStringHtml = dynamic<HtmlRenderProps>(() => {
  return import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.RenderStringHtml
  })
})

const RenderProductsCarousel = dynamic<RenderProductsCarouselProps>(
  () => {
    return import('components/ProductsCarousel/RenderProductsCarousel').then(
      (mod) => {
        return mod.RenderProductsCarousel
      }
    )
  },
  { ssr: false }
)

const BannersWeb = dynamic<PropsBannersWeb>(() => {
  return import('./BannersWeb').then((mod) => {
    return mod.BannersWeb
  })
})

export interface CustomTitles {
  h2: {
    customFontSize: string
  }
  h3: {
    customFontSize: string
  }
}

export const Home = ({
  contentTypeId = 'home',
  customMainPadding,
  data: serverData,
  hideMainFooter,
  isMobile,
  salesChannel
}: PropsHome) => {
  const router = useRouter()
  const { innerWidth } = useMobile()
  const [promotionalModalOpen, setPromotionalModalOpen] = useState(true)

  const { isLoggedIn } = useSession()
  const containerPadding =
    customMainPadding || (isMobile ? '16px 0 0 0' : '32px 0 0 0')

  const { elementRef: refCase, isIntersecting: isIntersectingCase } =
    useOnScreen({ rootMargin: '0px' })
  const { elementRef: refTimer, isIntersecting: showTimer } = useOnScreen({
    rootMargin: '0px'
  })
  const { data: userMembership } = useUserWithMembership()
  const isFallback = router.isFallback
  const componentsToFirstLoad = isMobile ? 3 : 4

  const customTitles: CustomTitles = {
    h2: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-xs')
        : getGlobalStyle('--font-size-titles-lg')
    },
    h3: {
      customFontSize: isMobile
        ? getGlobalStyle('--font-size-titles-2xs')
        : getGlobalStyle('--font-size-titles-md')
    }
  }

  const clientData = useContentful({
    options: { content_type: contentTypeId },
    reactQuery: { enabled: !serverData }
  })
  const populatedData = clientData.data
    ? new ContentfulResponse(clientData.data as IContentfulResponse)
        .populateEntries()
        .getResponse()
    : []

  const promotionalModalResult = useContentful({
    options: {
      content_type: 'inAppModal',
      include: 1,
      limit: 1,
      'fields.idFormato': 1,
      'fields.startDate[lte]': new Date().toISOString(),
      'fields.endDate[gte]': new Date().toISOString(),
      'fields.platform': 'Web Unimarc eComm',
      'fields.status': true
    }
  })

  const { data: sponsoredHomeBanners } = useSponsoredBannersHome({ isMobile })

  const promotionalModal: IInAppModal =
    promotionalModalResult?.data?.['items']?.[0]?.fields || null

  const promotionalModalImage =
    promotionalModalResult.data?.['includes']?.Asset?.[0]?.fields || {}

  const dataWithoutSponsor = serverData || populatedData

  const data = handleSponsoredBannersHome(
    dataWithoutSponsor,
    sponsoredHomeBanners
  )

  let undefinedContent = 0

  // custom hook to hide footer
  useFullHiderFooter(hideMainFooter)

  const handleTogglePromotionalModal = () => {
    setPromotionalModalOpen(!promotionalModalOpen)
  }

  const handleRenderValidate = ({ isRender = false, index = 0 }) => {
    return (
      isRender || index < componentsToFirstLoad || typeof window === 'undefined'
    )
  }

  const sendPromoData = ({ cfIndex, data, ref }: SendPromoDataProps) => {
    let promoData: SendPromoDataImageBanner = data
    promoData = {
      creativeName: promoData['creativeName'],
      creativeSlot: promoData['creativeSlot'],
      locationId: cfIndex,
      promotionId: promoData['promotionId'],
      promotionName: promoData['promotionName'],
      urlPath: promoData['reference']
    }
    addToHomeData({ ref, promoData })
  }

  const handleClickBanner = (promosData, index) => {
    clickedPromos({
      ...promosData,
      locationId: `${index + 1 - undefinedContent}`
    })
  }
  useEffect(() => {
    generateBreadcrumbJoin([])
    visiblePromosScroll({})
    const promotionalModalState = getPromotionalModalSeenCookie()
    if (!promotionalModalState) {
      setPromotionalModalOpen(true)
      setPromotionalModalSeenCookie(true)
    }
  }, [])

  if (!data || isFallback) {
    return <BackboneHome />
  }
  const regex = /showcaseWeb/i
  const found = isValidArrayWithData(Object.entries(data))
    ? Object.entries(data).map((item) => {
        return regex.test(item[0]) && item[1]?.['carousel']?.length > 0
      })
    : null

  return (
    <Container
      backgroundColor={getGlobalStyle('--color-base-white')}
      margin={isMobile || found?.[0] ? 'none' : '0.7px 0 0 0'}
      padding={containerPadding}
      position='relative'
      tagName='main'
    >
      {promotionalModalOpen && !!promotionalModal && (
        <PromotionalModal
          imageSrc={promotionalModalImage?.file?.url}
          isMobile={isMobile}
          linkWrapper={Link}
          onToggle={handleTogglePromotionalModal}
          open={promotionalModalOpen && !!promotionalModal}
          redirectTo={promotionalModal?.actionUrl}
        />
      )}

      <Column
        alignItems='center'
        gap={isMobile ? 32 : 56}
      >
        {data &&
          Object.entries(data)?.length > 0 &&
          Object.entries(data).map((content, index) => {
            // terms and conditions
            if (
              /term/i.test(content[0]) &&
              Object.keys(content[1]).length > 1
            ) {
              return (
                <Column
                  alignItems='center'
                  key={index}
                  ref={refCase}
                >
                  <PagesContainerDescription
                    isMobile={isMobile}
                    items={content[1]}
                  />
                </Column>
              )
            }

            // carousel showcaseWeb
            if (
              /showcaseWeb/i.test(content[0]) &&
              content[1]?.['slider']?.length > 0
            ) {
              return (
                <Column
                  alignItems='center'
                  key={index}
                  ref={refCase}
                >
                  {handleRenderValidate({
                    isRender: isIntersectingCase,
                    index
                  }) && (
                    <ShowCaseWeb
                      cfIndex={index + 1 - undefinedContent}
                      customTitles={customTitles}
                      dataCarouselBanner={content[1]['slider']}
                      dataCarouselProduct={
                        content?.[1]?.['carousel']?.[0] ?? []
                      }
                      isMobile={isMobile}
                      salesChannel={salesChannel}
                    />
                  )}
                </Column>
              )
            }

            // time offer banner
            if (
              /offerBanner/i.test(content[0]) &&
              offerBannerValidator(content[1])
            ) {
              const itemSaleChannels =
                content[1]?.['image']?.['fields']?.['saleChannels']

              if (
                itemSaleChannels &&
                !itemSaleChannels?.includes(salesChannel)
              ) {
                return null
              }

              return (
                <Container
                  justifyContent='center'
                  key={index}
                  ref={refTimer}
                >
                  <Column alignItems='center'>
                    {handleRenderValidate({ isRender: showTimer, index }) && (
                      <TimeOfferBanner
                        bannerSalesChannel={
                          content[1]?.['image']?.['fields']?.['saleChannels']
                        }
                        catchPromoData={(promoData?: object) => {
                          sendPromoData({
                            cfIndex: `${index + 1 - undefinedContent}`,
                            data: content[1]?.['image']?.['fields'],
                            ref: promoData
                          })
                        }}
                        endDate={new Date(content[1]?.['endDate'])}
                        href={content[1]?.['image']?.['reference']}
                        imgSrcDesktop={
                          content[1]?.['image']?.['imageDesktop']?.['fields']?.[
                            'file'
                          ]?.['url']
                        }
                        imgSrcMobile={
                          content[1]?.['image']?.['imageMobile']?.['fields']?.[
                            'file'
                          ]?.['url']
                        }
                        isMobile={isMobile}
                        linkWrapper={Link}
                        onRedirect={() => {
                          handleClickBanner(
                            content[1]?.['image']?.['fields'],
                            index
                          )
                        }}
                        orderformSalesChannel={salesChannel}
                        startDate={new Date(content[1]?.['startDate'])}
                      />
                    )}
                  </Column>
                </Container>
              )
            }

            // banners
            if (/featureContentBannersWeb/i.test(content[0])) {
              return (
                // eslint-disable-next-line react/jsx-key
                <BannersWeb
                  cfIndex={index + 1 - undefinedContent}
                  data={content[1]}
                  isMobile={isMobile}
                  isPriority={index === 0}
                  saleChannel={salesChannel}
                  titleSizes={customTitles}
                />
              )
            }

            if (/productsCarouselWeb/i.test(content[0])) {
              const isMobileView = checkDevice({ isMobile, innerWidth })
              const refType: string = content[1]?.['referenceType']

              if (!['MYUSUALS', 'COLLECTION'].includes(refType?.toUpperCase()))
                return null
              if (refType?.toUpperCase() === 'MYUSUALS' && !isLoggedIn)
                return null

              return (
                // eslint-disable-next-line react/jsx-key
                <RenderProductsCarousel
                  content={content[1]}
                  customTitles={customTitles}
                  index={index}
                  isMobile={isMobileView}
                />
              )
            }

            if (/featureContentItem/i.test(content[0])) {
              const featureContentItem: FeatureContentItem = content[1]
              if (
                featureContentItem.itemFormat ===
                  FeatureContentItemFormat.CustomizedOffers &&
                isLoggedIn
              ) {
                const carouselContent = {
                  title: featureContentItem.title,
                  subtitle: featureContentItem.subtitle,
                  sectionIcon:
                    featureContentItem?.sectionIcon?.fields?.file?.url,
                  backgroundImage: featureContentItem?.items.find(
                    (imageBanner) => {
                      return imageBanner.unimarcEnabledLevels?.some(
                        (unimarcEnabledLevel) => {
                          return userMembership?.level?.includes(
                            unimarcEnabledLevel
                          )
                        }
                      )
                    }
                  )?.fields?.image?.fields?.file?.url,
                  referenceType: 'MYUSUALS',
                  url: ''
                }
                return (
                  <>
                    <RenderProductsCarousel
                      content={carouselContent}
                      customTitles={customTitles}
                      index={index}
                      isMobile={isMobile}
                      key={index}
                      type='myOffers'
                    />
                  </>
                )
              } else if (
                featureContentItem.itemFormat ===
                FeatureContentItemFormat.InteractiveTermsAndConditions
              ) {
                return (
                  <>
                    <BaseContainer
                      flexDirection='column'
                      maxWidth={getGlobalStyle('--width-max-desktop')}
                      padding={isMobile && '0 16px'}
                    >
                      {featureContentItem?.items?.map((document) => {
                        return (
                          <BaseContainer
                            key={document?.sys?.id}
                            margin='0px 0px 32px'
                          >
                            <DownloadableTermsAndConditions
                              isMobile={isMobile}
                              title={document?.title}
                              url={document?.file?.fields?.file?.url}
                            />
                          </BaseContainer>
                        )
                      })}
                    </BaseContainer>
                  </>
                )
              }
              return null
            }

            if (/term/i.test(content[0]) && content[1]?.html) {
              return (
                <RenderStringHtml
                  htmlString={content[1].html}
                  key={index}
                />
              )
            }

            if (/Form/i.test(content[0])) {
              const formType = content[1]?.type

              if (
                (isMobile && formType === 'App') ||
                (!isMobile && formType === 'Web')
              ) {
                return (
                  <Form
                    form={content[1]}
                    isMobile={isMobile}
                    key={index}
                  />
                )
              }
            }

            if (
              /sponsoredBanner/i.test(content[0]) &&
              router.pathname === '/'
            ) {
              return (
                <Container
                  justifyContent='center'
                  key={index}
                  padding={isMobile ? '0 16px' : '0 32px'}
                >
                  <SponsoredBanner
                    content={content?.[1]}
                    isMobile={isMobile}
                  />
                </Container>
              )
            }

            undefinedContent += 1
            return null
          })}
      </Column>
    </Container>
  )
}
